<template>
  <div
    style="position: absolute; overflow: hidden; bottom: 0; width: 100%; left: 0"
    class="d-flex flex-row justify-center white--text grey darken-4"
    >
    <div
      class="py-2 caption"
      >
      © 2022 Gonzalez Raíces - Todos los derechos reservados.
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>
