<template>
  <v-app-bar
    app
    flat
    color="rgba(22, 22, 22, .8)"
    height="100"
    class="px-md-8 px-1"
    >
    <v-toolbar-title
      class="font-weight-bold white--text text-h4"
      >
      <v-img
        @click="$router.push({ name: 'home' })"
        :src="require('@/assets/images/logo.png')"
        :width="$vuetify.breakpoint.mdAndUp ? 300 : 260"
        :height="$vuetify.breakpoint.mdAndUp ? 90 : 70"
        ></v-img>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      class="d-flex flex-row"
      style="gap: 8px"
      >
      <v-btn
        text
        color="white"
        :to="{ name: 'search' }"
        >
        Propiedades
      </v-btn>

      <v-btn
        text
        color="white"
        :to="{ name: 'tasaciones' }"
        >
        Tasaciones
      </v-btn>

      <v-btn
        text
        color="white"
        :to="{ name: 'developments' }"
        >
        Emprendimientos
      </v-btn>

      <v-btn
        text
        color="white"
        :to="{ name: 'about_us' }"
        >
        Nosotros
      </v-btn>

      <v-btn
        text
        color="white"
        :to="{ name: 'home', hash: '#contacto' }"
        >
        Contacto
      </v-btn>
    </div>

    <v-menu 
      v-else
      offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          v-on="on"
          >
          <v-icon
            large
            >
            mdi-menu
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :to="item.route"
          >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    color: 'transparent',
    items: [
      {
        name: 'Propiedades',
        route: {
          name: 'search',
        }
      },
      {
        name: 'Tasaciones',
        route: {
          name: 'tasaciones',
        }
      },
      {
        name: 'Emprendimientos',
        route: {
          name: 'developments',
        }
      },
      {
        name: 'Nosotros',
        route: {
          name: 'about_us',
        }
      },
      {
        name: 'Contacto',
        route: {
          name: 'home',
          hash: '#contacto'
        }
      },
    ]
  }),

  watch: {
    '$route.name' (val) {
      if (['home', 'about_us'].includes(this.$route.name)) {
        this.color = 'transparent'

        window.onscroll = () => {
          this.changeColor();
        };
      } else {
        this.color = '#111'
      }
    }
  },

  mounted() {
    if (['home', 'about_us'].includes(this.$route.name)) {
      this.color = 'transparent'
      window.onscroll = () => {
        this.changeColor();
      };
    } else {
      this.color = '#111'
    }
  },

  methods: {
    changeColor() {
      if (['home', 'about_us'].includes(this.$route.name)) {
        if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
        ) {
          this.color = '#111';
        } else {
          this.color = 'transparent';
        }
      }
    },

    goToWhatsapp () {
      const base = "https://wa.me/56984799318?text="
      const text = `¡Hoy es un gran día para emprender! Estoy necesitando...`
      const uri = base + text

      window.open(uri)
    },
  },
}
</script>
