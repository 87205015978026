<template>
  <v-app id="inspire">
    <app-bar></app-bar>

    <v-main
      :class="{ 'pt-0': ['home', 'about_us'].includes($route.name) }"
      >
      <router-view />

      <Footer></Footer>
    </v-main>

    <v-btn
      v-if="!['property', 'development'].includes($route.name)"
      color="success"
      fab
      :small="$vuetify.breakpoint.smAndDown"
      fixed
      bottom
      right
      depressed
      @click="goToWhatsapp"
      >
      <v-icon>
        mdi-whatsapp
      </v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import AppBar from '@/components/bars/AppBar.vue'
import Footer from '@/components/home/Footer'

export default {
  name: "App",

  methods: {
    goToWhatsapp () {
      const base = "https://wa.me/5491163651597"
      const uri = base

      window.open(uri)
    },
  },

  components: {
    AppBar,
    Footer
  }
};
</script>
