import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from 'axios'
import "@/style/aplication.scss";
import * as VueGoogleMaps from "vue2-google-maps"

Vue.config.productionTip = false;

Vue.use(axios)

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB3RTiKtL9Wx4PT1gwp9FsRGsDu9qJittc",
    libraries: "places", // necessary for places input
    region: "ar",
    language: "es"
  }
})

Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
