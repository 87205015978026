import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "/propiedades/:id",
    name: "property",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Property.vue"),
  },
  {
    path: "/buscar",
    name: "search",
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Search.vue"),
  },
  {
    path: "/tasaciones",
    name: "tasaciones",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Tasaciones.vue"),
  },
  {
    path: "/emprendimientos",
    name: "developments",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Developments.vue"),
  },
  {
    path: "/emprendimientos/:id",
    name: "development",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Development.vue"),
  },
  {
    path: "/nosotros",
    name: "about_us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutUs.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ 
            selector: to.hash, 
            top: 0,
            behavior: 'smooth',
          })
        }, 700)
      })
    } else {
      if (savedPosition) {
        return savedPosition
      }

      return { x: 0, y: 0 }
    }
  },
});

export default router;
